export default {
  dashboard: `<p>Dette er en oversikt over alle selskapene brukeren din har tilgang til.</p>
  <p>Dersom du er regnskapsfører og har byråtilgang, må dere velge at din bruker skal ha tilgang til de ulike selskapene under "Mitt regnskapsbyrå". Din bruker vil kun få opp selskaper som du har tilgang til under mitt regnskapsbyrå.</p>
  <p>Dersom du ønsker å legge til et nytt selskap, kan du trykke på "Nytt selskap" øverst til høyre.</p>
  <p>I oversikten ser du status, og hvor ajour du er med de ulike selskapene. Hvis du klikker på tekst i blått, er det en link og du vil  komme inn på valgt selskap til den relevante siden.</p>
  <p>Det er ulike filtre på toppen av skjermen, og du kan trykke på "Flere filtre" for å vise alle filtrene. Her kan du filtrere listen for enklere kontroll og oversikt over status på de ulike selskapene i listen.</p>`,
  generalLedger: `
<p>Hovedbok er en logg for hele regnskapet, der all bokføring vises.</p>
<p>Hovedboken organiserer de ulike kontoene som er brukt i den valgte perioden og viser alle hendelser som har skjedd på hver konto. Under hver konto vises følgende:</p>
<ul style="margin-bottom: 20px;">
  <li><b>Inngående saldo:</b> Viser beløpet som var bokført på hver konto frem til den valgte "fra"-datoen.</li>
  <li><b>Utgående saldo:</b> Viser beløpet som er bokført på hver konto siste dagen i den valgte "til"-datoen.</li>
  <li><b>Sum:</b> Viser alle posteringer på denne kontoen i den aktuelle perioden.</li>
</ul>
<p>I hovedboken kan du trykke på den blå lenken under transaksjonsnummeret for å åpne og redigere de ulike bokføringene.</p>
<p>Når du går inn på siden, vil du automatisk se datoene fra første dag i inneværende år til siste dag i inneværende år. Du kan når som helst oppdatere perioden. Merk at denne siden kan ta lengre tid å laste enn andre sider, da den henter ut data fra hele regnskapet over en lengre periode.</p>
`,
  products: `
  <p>
    Dette er oversikt over alle dine varer og tjenester du har valgt å lagre. Ved å legge inn et produkt gjør
    det faktureringen mer effektivt, ved at du slipper å lage samme produkt flere ganger. Hver gang du lager
    en faktura tilknyttet til et produkt blir dette også registrert, slik at du kan sener se rapporter om hvor
    mye du selger av de ulike produktene.
  </p>
  <p>
    Du kan sortere på alle kategorier som står på den blå linjen, ved å trykke på den kategorien du vil
    sortere. Trykk flere ganger for ulik sortering. Bruk søkefeltet for å søke etter et produkt.
  </p>
  <p>Produktnummer er valgfritt, og kan være en fin måte å lage god struktur.</p>
  <p>
    Det er ikke mulig å slette et produkt som er koblet til en faktura. Du kan registrere et produkt som
    inaktivt, slik at det ikke kommer på første-visning av denne siden. Ved å registrere et produkt som
    inaktivt, kommer det heller ikke opp når du lager en ny produktlinje når du skal lage en faktura.
  </p>`,
  invoices: `<p>Dette er en oversikt over alle lagde fakturaer, kreditnotaer, betalingspåminnelser og fakturautkast.
  </p>
  <p>
  Denne siden er hovedsakelig for å gi et overblikk over status og hva som må gjøres eller følges opp. Fakturaer som er oppgjort (betalt, kreditert etc) vil være skjult når du går inn på denne siden. Du kan alltids finne oversikt over alle fakturaer ved å velge dato fra og til, eller endre på filterne
  </p>
  <p>
  Dersom du lagrer en faktura som utkast vil den komme i oversikten “fakturautkast”. Du kan når som helst fortsette med samme faktura eller slette utkastet. Ingenting blir bokført eller registrert i regnskapet så lenge det er et utkast.
  </p>`,
  moduler: `<p>På denne siden ser du oversikt over alle moduler i Systima. Du ser også en oversikt over hvilken moduler som er aktive for valgte selskap. Dersom du har flere selskaper må du være logget inn på riktig selskap for å se riktig oversikt over modulene til dette selskapet.</p>
  <p>For spørsmål om våre betingelser kan du lese våre betingelser <a href="https://www.systima.no/brukeravtale" target="_blank">her</a>.</p>`,
  profile: `<p>På denne siden kan du oppdatere informasjon om din personlige profil. Du er til en hver tid ansvarlig for å holde informasjonen oppdatert ref våre betingelser.</p>
  <p>Din personlige profil kan ha tilgang til flere selskaper, og dersom du oppdaterer informasjon her vil din profil oppdatere seg på samtlige selskaper du har tilgang til.</p>`,
  projects: `<p>På denne siden kan du oppdatere informasjon om din personlige profil. Du er til en hver tid ansvarlig for å holde informasjonen oppdatert ref våre betingelser.</p>
  <p>Din personlige profil kan ha tilgang til flere selskaper, og dersom du oppdaterer informasjon her vil din profil oppdatere seg på samtlige selskaper du har tilgang til.</p>`,
  editInvoice: ` <p>Her ser du all fakturainformasjon som er tilknyttet til kun denne fakturaen.</p>
  <p>
    Øverst på siden finner du nøkkelinformasjon om kunden og fakturaen. Her kan du registrere
    betaling eller få flere valg ved å trykke på pilen ned på “register betaling” knappen. Sjekk
    nettbanken for å se om du har fått noen innbetalinger, og register de deretter med riktig dato
    og sum.
  </p>
  <p>
    Den viktigste informasjonen her er om faktura er sendt, om faktura har forfallt
    (betalingsfirsten har passert), hva kundene har betalt og hva kunden skylder. <br />NB: vi
    kommer senere med integrasjon direkte med nettbanken, så Systima automatisk kan lese
    transaksjoner inn / ut av bankkontoen.
  </p>
  <p>Fra denne siden kan du forhåndsvise faktura, og også laste ned faktura som PDF.</p>
  <p>
    Boksen nederst på siden viser utdypet informasjon om fakturaen, hvor du har flere faner hvor
    detaljer er sortert etter kategori:<br />
    <b>Salgslinjer</b> viser alle salgslinjene du lagde på selve fakturaen, hvor total sum for
    faktura er summert nederst.<br />
    <b>Saldo</b> viser alle transaksjoner tilknyttet til fakturaen, og hva kunden skylder.<br />
    <b>Kreditnota</b> viser en eller flere kreditnotaer som er knyttet til fakturaen<br />
    <b>Utsendelser</b> viser alle sendelser som er gjort til kunden<br />
    <b>Vedlegg</b> viser vedlegg som er laget med faktura, eller lastet opp i ettertid.<br />
  </p>
`,
  contacts: `
<p>
Dette er en side med alle dine kontakter. En kontakt kan både være privatpersoner og selskaper. En kontakt kan være kunde, leverandør eller begge deler.
</p>
<p>
Hvis du ønsker å legge til en ny kontakt, trykker du på “Ny kontakt” knappen. Dersom du skal legge inn mange kontakter kan det være smart å bruke "importer kontakter" funksjonen vår . Via denne kan du enten bruke vår mal eller laste opp xls, xlsx eller csv fil fra andre systemer. Dersom du laster opp en fil fra et annet system og får en feilmelding, kan du sends oss filen via e-post. På denne måten kan vi tillate import også fra dette systemet.
</p>
<p>
Når du oppretter en kontakt får kontaktet automatisk et unikt kundenummer og leverandørnummer.
</p>
<p>
Det er mulig å opprette samme kunde / leverandør flere ganger, men vi anbefaler og ikke gjøre dette. Det er mer ryddig og kun ha en kontakt per kunde/leverandør, slik at alt av statistikk er samlet. Eksempelvis når du lager eller mottar en faktura, vil du kunne se alt samlet for denne kontakten.
</p>
<p>
Øvre del av siden er søkefelt og filter. Her kan du søke på alle type verdier som oppgis i tabellen. Det er også mulig å filtrere slik at du ser alle type kontakter, kun leverandører eller kun kunder.
</p>
<p>
Det er ikke mulig å slette en kunde som er koblet til en faktura.
</p>
`,
  employees: `
<p>
Dette er en oversikt over alle ansatte. Når du kommer inn på siden viser den kun aktive ansatte, som betyr at de aktuelle ansatte er ansatt den datoen du går inn på siden. Når et arbeidsforhold ikke lenger er aktivt vil statusen på den ansatte automatisk bli inaktiv. Du kan når som helst søke på ansatte eller ta på filter for og inkludere inaktive ansatte i oversikten.
</p>
<p>
For å legge til en ny ansatt trykker du på “Ny ansatt” knappen.
</p>
<p>
Ansatte som blir lagt inn i åpningsbalansen vil automatisk bli lagt inn i denne oversikten. Disse ansatte kan ha manglende informasjon, og du vil da måtte oppdatere informasjonen før en lønnskjøring.
</p>
`,
  chartOfAccounts: `
<p>Systima bruker Regnskap Norge sin kontoplan, og alle nye selskaper som blir opprettet vil automatisk få alle disse kontoene i sin kontoplan.</p>
<p>Den mva. koden som er lagt til på en konto er den mva. koden som vil bli foreslått når du bokfører med denne kontoen. Dette er gjort for mer effektiv bokføring.</p>
<p>Dersom mva. koden er låst, vil det ikke være mulig å endre mva. kode under bokføring. Dersom du ikke ønsker at en konto skal ha låst mva. kode må du gå tilbake til kontoplanen og avkrysse på lås mva.</p>
<p>Du kan legge til og redigere kontoer slik du ønsker. Det er ikke tillat å endre kontonummer, men du står fritt til å redigere alle andre verdier. Dersom du endrer kontonavn vil du alltids ha mulighet til å tilbakestille navnet til originalt navn. Alle kontoer må ha unike nummer, så det er ikke mulig å opprette to kontoer med samme nummer.</p>
`,
  trialBalance: `
<p>Saldobalansen viser en oversikt over hva som er inngående saldo, utgående saldo og endring av saldo i en angitt periode per regnskapskonto. Når du går inn på denne siden vil du kun se de kontoene du har saldo på. Du kan krysse av filteret for å vise alle kontoene i kontoplanen.</p>
<p>Øverst på siden kan du velge dato fra og til. Inngående saldo vil da være fra dato, og utgående saldo vil være til dato. Endringen er hva som er endret i denne perioden.</p>
<p>Når du går inn på siden vil du automatisk se datoene fra første dag i dette året til siste dag i dette året. Du kan når som helst oppdatere perioden.</p>
<p>Under hver kontoklasse er det en oppsummeringslinje hvor du ser totalt inngående, totalt utgående og totale endringer på denne kontoklassen. Dette er for å gi bedre overblikk og en oppsummering. Det er også lagt opp egne oppsummeringer på hva som påvirker egenkapitalen og gjelden.</p>
`,
  departments: `
<p>Fra denne siden kan du legge til og redigere avdelinger for virksomheten.</p>
<p>Du trykker på “Ny avdeling” knappen for å legge til ny. Herfra kan du legge inn avdelingsnummer (valgfritt) og navn på avdelingen. Når du lagrer, vil du se avdelingen som en del av listen.</p>
<p>Alle avdelinger vil være en del av listen, og du kan trykke på en linje eller trykke på redigerings-pennen helt til høyre for å redigere en avdeling</p>
`,
  purchase: `
<p>Bokføring kjøp gir deg muligheten til å bokføre leverandørfakturaer og kvitteringer. Dersom du har aktivert bankintegrasjon kan du enkelt legge fakturaer til betaling via betalingskortet.</p>
<strong>Du kan bruke følgende hurtigtaster:</strong>
<ul style="list-style: none; padding-left: 0; margin-bottom: 20px;">
  <li>Ctrl s = Bokfør / lagre bilag</li>
  <li>Ctrl d = Slett linjen du er på (hvor du har et markert felt)</li>
  <li>Ctrl e = Dupliser linjen du er på (hvor du har et markert felt)</li>
  <li>Enter = Velg verdi og gå til neste felt i bokføringsflyt</li>
  <li>Tab = Gå til neste felt</li>
  <li>Shift tab = Gå til forrige felt</li>
</ul>
  `,
  sale: `
<p>Bokføring salg gir deg muligheten til å bokføre kundefakturaer og kvitteringer.</p>
<strong>Du kan bruke følgende hurtigtaster:</strong>
<ul style="list-style: none; padding-left: 0; margin-bottom: 20px;">
  <li>Ctrl s = Bokfør / lagre bilag</li>
  <li>Ctrl d = Slett linjen du er på (hvor du har et markert felt)</li>
  <li>Ctrl e = Dupliser linjen du er på (hvor du har et markert felt)</li>
  <li>Enter = Velg verdi og gå til neste felt i bokføringsflyt</li>
  <li>Tab = Gå til neste felt</li>
  <li>Shift tab = Gå til forrige felt</li>
</ul>
  `,
  manualTransaction: `
<p>Bokføring fri gir deg muligheten til å bokføre fritt på debet konto og kredit konto. Du kan legge til en eller flere linjer med ulike datoer og summer.</p>
<strong>Du kan bruke følgende hurtigtaster:</strong>
<ul style="list-style: none; padding-left: 0; margin-bottom: 20px;">
  <li>Ctrl s = Bokfør / lagre bilag</li>
  <li>Ctrl d = Slutt linjen du er på (hvor du har et markert felt)</li>
  <li>Ctrl e = Dupliser linjen du er på (hvor du har et markert felt)</li>
  <li>Ctrl b = Bytt plass på debet og kredit kontoer og mva koder.</li>
  <li>Enter = Velg verdi og gå til neste felt i bokføringsflyt</li>
  <li>Tab = Gå til neste felt</li>
  <li>Shift tab = Gå til forrige felt</li>
</ul>
  `,
  controlPanelCustomers: `<p>Control Panel Customers Description</p>`,
  controlPanelEmployees: `<p>Control Panel Employees Description</p>`,
  openingBalance: `
  <p>Dersom denne virksomheten tidligere har ført regnskap utenfor Systima bruker du åpningsbalansen.</p>
  <p>I åpningsbalansen fyller du inn alle inngående saldo på alle kontoer i regnskapet du har brukt tidligere. Du må derfor avslutte regnskapet 100% der du tidligere har ført regnskap frem til en viss dato, så alle utgående saldoer er korrekte. Dersom ikke inngående saldo i Systima samstemmer 100% med utgående saldo i tidligere system vil regnskapet bli feil.</p>
  <p>Vi anbefaler at du bruker maler for å gjennomføre åpningsbalansen. Du kan også laste opp rapporter direkte fra flere andre regnskapsprogrammer.</p>
  <p>Du trenger ikke å fullføre åpningsbalansen med en gang du starter å bruke Systima. Du kan gjøre dette frem til årsregnskapet for det året skal leveres. Du vil dog se misvisende tall i regnskapet frem til årsregnskapet er ferdig.</p>
  <p>Åpningsbalansen kan lagres som utkast dersom du ikke ønsker å bokføre den. Når du bokfører åpningsbalansen opprettes det et bilagsnummer for hele åpningsbalansen. Du kan senere gå inn og redigere åpningsbalansen ved å bokføre på nytt. Du vil da fortsatt jobbe med samme bilagsnummer, men endre eventuelle føringer.</p>
  `,
  lockPeriod: `<p>På denne siden kan du manuelt låse alle handlinger i regnskapet til og med en dato.</p>
  <p>Det vil ikke være mulig å opprette eller redigere noen bokføringer i den perioden som er låst. Dersom du forsøker dette vil du få en advarsel med informasjon om at du må gå hit for å låse opp perioden.</p>
  <p>Du kan når som helst endre dato, eller fjerne dato helt.</p>`,
  integrations: `<p>På denne siden kan du aktivere integrasjoner mot Systima. Integrasjonene kan være utviklet av Systima eller tredjepartsleverandør. Hvilke aktør kundeforholdet opprettet mot, og hvor fakturaen sendes fra vil oppgis i pop-upen som vises når du trykker på “Aktiver”. Prisene som er oppgitt er “priser fra”. Det vil si at prisene kan være høyere for noen deler av integrasjonene. Eksakte priser finner du på linken som oppgis i pop-upen som vises når du trykker på “Aktiver”. Estimert tid til integrasjonen er aktiv vil kunne variere.</p>`,
  paymentSettings: `
<p>Betalingsinnstillinger er en side der du oppdaterer all relevant informasjon knyttet til betalinger. Her kan du utføre ulike handlinger, som å legge til flere betalingskilder, legge til flere bankkontoer, oppdatere skattetrekkskonto og gjøre innstillinger for mer effektiv regnskapsføring.</p>
<p><b style="font-weight: 700;">Betalingskilder:</b> Dette er en oversikt over de ulike bankkontoene du ønsker å bruke når du bokfører handlinger. Navnet du legger inn her vises når du bokfører, for eksempel ved registrering av betaling i kjøpsbokføring. Du må velge hvilken konto i kontoplanen du ønsker å bokføre mot, og sikre at den valgte kontoen er knyttet til riktig bankkonto. Sekvens angir rekkefølgen når du bokfører, der 1 er øverst og høyeste sekvens er nederst. Du kan også velge når betalingskilden skal være tilgjengelig, for eksempel på utbetalinger. Disse innstillingene forenkler bokføringen ved å vise relevante betalingskilder på aktuelle sider.</p>
<p><b style="font-weight: 700;">Bankkontoer:</b> Dette gir en oversikt over selskapets bankkontoer som brukes i bankavstemming. Du må legge til alle selskapets bankkontoer for riktig avstemming. Når du legger til en bankkonto i denne listen, blir den automatisk inkludert i bankavstemmingen. Du må velge kontonummer for fakturaer og kan også legge til IBAN og BIC/SWIFT-informasjon for internasjonale betalinger. Oppdatert informasjon vises automatisk på utgående fakturaer. For å oppdatere kontoinformasjon kan du trykke på linjen eller velge de tre prikkene lengst til høyre.</p>
<p><b style="font-weight: 700;">Skattetrekkskonto:</b> Dette er en nødvendig konto for lønnsbokføring. Kontoen tilknyttet regnskapskonto 1950 vises her.</p>`,
  bankReconciliationOverview: `
<p>Dette er en oversikt over alle kontoer som skal bankavstemmes.</p>
<p>Når du åpner denne siden, vises kun de kontoene som ikke er ferdig avstemt. Øverst på siden finner du forskjellige filtre hvor du kan endre perioden, vise avstemte kontoer, og se en oversikt over hvor mange kontoer som er avstemt og ikke.</p>
<p>Du kan kun avstemme en konto hvis siste periode er avstemt først. Du kan derfor ikke hoppe over en periode.</p>
<p>Når en konto blir registrert som avstemt, låses denne perioden i regnskapet. Du kan derfor ikke bokføre eller endre eksisterende bokføringer mot denne bankkontoen i samme periode før du manuelt låser opp perioden.</p>
<p>Med bankintegrasjon kan Systima vise en sanntids fremdriftsindikator (live) som viser andelen av perioden som er avstemt. Dette gir oversikt både per avstemt konto og totalt for alle kontoer.</p>
<p>Det er to typer bankavstemminger:</p>
<ol>
  <li>Automatisk bankavstemming: For bankkontoer med bankintegrasjon eller ved opplasting av CSV-fil. Med integrasjonsinformasjonen eller CSV-filen kan Systima foreslå ulike avstemminger for å automatisere prosessen. Vi anbefaler bruk av bankintegrasjon på alle kontoer.</li>
  <li>Manuell bankavstemming: For de uten bankintegrasjon eller CSV-fil. Her må du manuelt markere hver transaksjon, og Systima kan ikke hjelpe med automatisering.</p>
</ol>
<p>For å oppdatere bankkontoer, gå til Betalingsinnstillinger, der du også kan konfigurere bankintegrasjon.</p>
`,
  bankReconciliationManual: `
<p>Manuell bankavstemming er for bankkontoer som ikke er tilknyttet bankintegrasjon eller brukt CSV-fil. Vi anbefaler å bruke bankintegrasjon for alle bankkontoer for mest effektiv regnskapsføring.</p>
<p>Bankavstemming er en regnskapsrutine for å dobbeltsjekke at all bokført data på en bankkonto i en gitt periode samsvarer nøyaktig med kontoutskriften. Det skal være eksakt de samme summene inn og ut uten unntak. Bankavstemming kvalitetssjekker summene og bekrefter deres identitet.</p>
<p>Det anbefales sterkt å laste opp PDF av kontoutskriften på denne siden. Forsikre deg om at du har valgt riktig kontonummer og periode. Det anbefales å ha PDF synlig i forhåndsvisningen på halve skjermen mens du kontrollerer summene på den andre halvdelen.</p>
<p>Listen på venstre del av skjermen viser alle bokførte handlinger mot denne bankkontoen i den angitte perioden. Hvis noen bokføringer er feil, kan de rettes, og hvis det mangler noen bokføringer, kan du bruke bokføringsknappen øverst.</p>
<p>Hver bokført handling på venstre side har en fargekodet avmerkingsboks. Det er opp til deg hvordan du bruker fargene, og det påvirker ikke registreringen i avstemmingen. Dette er for enklere kontroll og oversikt, spesielt hvis du ønsker å fortsette avstemmingen senere.</p>
<p>Det anbefales å starte øverst på kontoutskriften og dobbeltsjekke at du finner samme dato, leverandør og sum som er bokført i Systima på venstre side. Hvis transaksjonen er korrekt, anbefales det å merke av som grønt. Hvis noe må rettes, anbefales det å merke det som gult, og hvis noe er bokført i Systima, men ikke synlig på kontoutskriften, anbefales det å merke det som rødt.</p>
<p>Hvis du mangler en transaksjon mens du går gjennom kontoutskriften, anbefales det å finne bilaget og bokføre som vanlig. Denne siden vil da automatisk oppdatere seg og vise den nybokførte hendelsen.</p>
<p>Når alle bokførte handlinger samsvarer med kontoutskriften, må du dobbeltsjekke at innkommende og utgående saldo er korrekt. Hvis begge saldopostene er riktige, kan du godkjenne bankavstemmingen. Hvis innkommende saldo er feil, har du mest sannsynlig gjort feil i tidligere bankavstemminger. Hvis utgående saldo er feil, har du sannsynligvis bokført for mye eller for lite i den valgte perioden.</p>
<p>Når du godkjenner bankavstemmingen, låser du den slik at det ikke er mulig å bokføre eller redigere eksisterende bokføringer mot denne bankkontoen i den angitte perioden. Det er mulig å låse opp perioden manuelt, men da må du gjennomføre en ny bankavstemming.</p>
<p>For å oppdatere bankkontoer, gå til Betalingsinnstillinger, hvor du også kan konfigurere bankintegrasjon.</p>
`,
  bankReconciliationAuto: `
<p>Automatisk bankavstemming gjelder for bankkontoer med bankintegrasjon eller bruk av CSV-fil.</p>
<p>Bankavstemming er en regnskapsrutine for å dobbeltsjekke at all bokført data på en bankkonto i en angitt periode samsvarer nøyaktig med kontoutskriften. Summene ut og inn skal være eksakt de samme uten unntak. Bankavstemming kvalitetssjekker summene og bekrefter deres identitet.</p>
<p>Listen på venstre side viser alle bokførte handlinger mot denne bankkontoen i den angitte perioden. Hvis noen bokføringer er feil, kan de rettes, og hvis det mangler noen bokføringer, kan du bruke bokføringsknappen øverst. Listen på høyre side viser faktiske transaksjoner på bankkontoen som du skal kontrollere mot.</p>
<p>Du skal matche transaksjoner på venstre og høyre side slik at summene er nøyaktig like. Når du har matchet, kan du klikke på "Avstem" i den grønne knappen. Når dette er gjort, er disse transaksjonene avstemt. Dette gjøres for alle transaksjonene.</p>
<p>Det er mulig å avstemme flere transaksjoner samtidig, og det er også mulig å matche en transaksjon på venstre side med flere transaksjoner på høyre side.</p>
<p>Hvis det mangler transaksjoner på venstre side, har du sannsynligvis glemt å bokføre disse handlingene eller bokført feil.</p>
<p>Når alle transaksjoner er matchet, må du dobbeltsjekke at innkommende og utgående saldo er korrekt. Hvis begge saldopostene er riktige, kan du godkjenne bankavstemmingen. Hvis innkommende saldo er feil, har du mest sannsynlig gjort feil i tidligere bankavstemminger. Hvis utgående saldo er feil, har du mest sannsynlig bokført for mye eller for lite i den valgte perioden.</p>
<p>Når du godkjenner bankavstemmingen, låser du den, slik at det ikke er mulig å bokføre eller redigere eksisterende bokføringer mot denne bankkontoen i den angitte perioden. Det er mulig å låse opp perioden manuelt, men da må du gjennomføre en ny bankavstemming.</p>
<p>For å oppdatere bankkontoer, gå til Betalingsinnstillinger, hvor du også kan konfigurere bankintegrasjon.</p>`,
  balanceSheet: `
<p>Balanserapport, også kjent som balanseregnskap, gir en oversikt over eiendeler, egenkapital og gjeld.</p>
<p>Hver gang du bokfører mot en kontoklasse 1 eller 2, påvirker det balansen. Bokføringer mot kontoklasse 1 representerer eiendeler, mens bokføring mot kontoklasse 2 representerer egenkapital og gjeld.</p>
<p>Øverst på siden kan du velge perioden du ønsker å se. Når du går inn på denne siden, vil du automatisk se data fra den første dagen i inneværende år til den siste dagen i inneværende år.</p>
<p>Dersom du ønsker å se inntekter, kostnader og overskudd i selskapet, må du gå til resultatrapporten. Her vises alt som blir bokført mot kontoklasse 3 til 8, som representerer inntekter og kostnader.</p>
`,
  incomeStatement: `
<p>Resultatrapport, også kjent som resultatregnskap, gir en oversikt over inntekter og kostnader og oppdaterer resultatet/overskuddet.</p>
<p>Hver gang du bokfører mot en kontoklasse mellom 3 og 8, påvirker det resultatrapporten. Bokføringer mot kontoklasse 3 representerer salg, mens bokføringer mot de øvrige kontoklassene representerer kostnader, med unntak av krediteringer. Kontoklassen er det første sifferet til en konto i regnskapet.</p>
<p>Øverst på siden kan du velge perioden du ønsker å se. Når du går inn på denne siden, vil du automatisk se data fra den første dagen i inneværende år til den siste dagen i inneværende år.</p>
<p>Dersom du ønsker å se eiendelene i selskapet, må du gå til balanserapporten. Her vises alt som blir bokført mot kontoklasse 1 og 2, som representerer eiendeler, egenkapital og gjeld.  </p>
`,
  timesheet: `<p>På denne siden kan du både legge inn timer på ulike arbeidsforhold og se antall timer som er lagt til totalt på de ulike arbeidsforholdene.</p><p>Øverst på siden velger du perioden. Når du har valgt periode kan du både føre timer i den perioden, og se oversikt over alle førte timer i perioden.</p>`,
  timesheetsSettings: `
  <p>Denne siden gir deg tilgang til alle innstillinger som er knyttet til timeføring.</p>
  <p>Det er flere innstillinger på denne siden som er standard for alle virksomheter når du aktiverer timeføring. Det er anbefalt å gå over alle innstillinger før selskapet starter å bruke timeføring, for å forhindre eventuelle feilregistreringer.</p>`,
  vatSettings: `
  <p>Dette er en oversikt over alle mva. koder tilknyttet virksomheten.</p>
  <p>Du kan klikke på en linje for å komme i redigeringsmodus for valgt mva. kode. For å legge til en ny mva. kode, klikker du på "Ny mva. kode" øverst til høyre. Systemet er fleksibelt slik at du kan velge de verdiene du selv ønsker på ny mva. kode.</p>
  <p>Alle virksomheter får alle standard mva. koder fra Skatteetaten. Disse er ikke mulig å redigere, men de kan aktiveres eller deaktiveres for å skjule dem når man bruker Systima. Disse vil stå som "nei" under redigerbar kolonnen.</p>
  <p>Det er mulig å legge til egne mva. koder og knytte disse opp mot de standard mva. kodene. Her vil du ha mulighet til å velge forholdsmessig mva, og deretter legge til hvor stor prosentandel som skal være forholdsmessig. Egenproduserte mva. koder har mer fleksibilitet på redigering. Disse mva. kodene vil ha verdi "ja" under redigerbar kolonnen.</p>
  <p>Dersom en mva. kode er inaktiv, vil den ikke være synlig når man bokfører. Det samme gjelder om du bokfører med posteringsdato i en periode etter "gyldig til"-datoen.</p>
  `,
  compilationReport: `
<p>Her presenteres en oversikt over ansatte som har mottatt lønn i den valgte perioden og dermed er berettiget til å motta en sammenstillingsrapport.</p>
<p>Du har enkelt muligheten til å sende eller laste ned sammenstillingsrapport. Ved å klikke på "Last ned"-knappen øverst til høyre, lastes rapportene ned for de ansatte som vises i tabellen. Alternativt, hvis du ønsker å laste ned for en spesifikk ansatt, kan du trykke på de tre prikkene til høyre i  vedkommendes linje og trykke "Last ned" herfra.</p>
<p>I tabellen presenteres en status som indikerer om rapporten er sendt. Du har også oversikt over eventuelle endringer, for eksempel nye innsendte a-meldinger etter at rapporten er sendt. Det er mulig å sende rapporten så mange ganger som nødvendig, og dette gir deg fleksibilitet i håndteringen av rapporteringsprosessen.</p>
  `,
  holidayPay: `
<p>Denne rapporten gir en  oversikt over feriepenger for ansatte i virksomheten i oppgitt periode.</p>
<p>Øverst på siden har du muligheten til å velge en spesifikk tidsperiode. Tabellen oppdateres deretter for å vise alle ansatte som har minst ett aktivt arbeidsforhold i den angitte perioden. Dersom en ansatt har flere arbeidsforhold, presenteres vedkommende kun én gang i tabellen, med en samlet oversikt over feriepenger for alle arbeidsforhold. For ytterligere detaljer kan man klikke på den enkelte ansatt for å få tilgang til ansattkortet, som inneholder mer utfyllende informasjon.</p>
<p>Tabellen innledes med en visning av inngående saldo, som er den inngående saldoen til den valgte perioden. Deretter presenteres grunnlaget, satsen og opptjente feriepenger for den spesifikke perioden. Dersom det er ansatte som er 59+ år, inkluderes også disse tallene.</p>
<p>Den siste delen av tabellen gir oversikt over utbetalinger i den valgte perioden, samt saldoen som står til gode for den ansatte på siste dagen av den valgte perioden.</p>
<p>Rapporten oppdateres automatisk når en ny lønn som inkluderer feriepenger blir bokført.Dersom en ansatt mottar utbetaling av feriepenger, vises dette i de tilhørende kolonnene for utbetaling og til gode.</p>
<p>For en fullstendig gjennomgang av alle lønningene til en ansatt, kan man enkelt kontrollere informasjonen ved å klikke på den ansatte eller ved å gå til lønnsoversikten.</p>
<p>Dersom tabellen viser feil, har du mest sannsynlig bokført en feil lønn. Da er det anbeflat å gå til lønnsoversikten og velge samme periode for å kontrollere de ulike lønningene.</p>
`,
  vouchers: `
  <p>Dette er en rapport som viser alle bilag som er bokført i Systima.</p>
  <p>Oversikten er automatisk sortert med de nyeste bilagene øverst, men det er mulig å endre slik at de eldste bilagene vises først.</p>
  <p>Øverst i rapporten finnes ulike filtre. Klikk på "flere filtre" for å se alle tilgjengelige filtre. Du kan bruke filtrene individuelt eller i kombinasjon. Hvis du bruker et filter, vil vi kun vise relevante bilag basert på det valgte filteret.</p>
  <p>Rapporten viser alle bokførte bilag med tilhørende posteringer. Hvis det er blå tekst i rapporten, er det en lenke for å få mer detaljert informasjon i systemet.</p>
  `,
  vatSpecificationOverview: `
<p>Denne rapporten er en MVA-spesifikasjon som gir et godt overblikk over hvilke kontoer og MVA-koder som er brukt i samarbeid.</p>
<p>Øverst i rapporten er det mulig å benytte seg av ulike filtre. Filtrene kan brukes i fellesskap. For å se alle filtrene må du klikke på den blå knappen "flere filtre" øverst til høyre. Systemet genererer en unik URL når du bruker filter, slik at det er enkelt å dele lenker.</p>
<p>Rapporten oppsummerer kun brukte MVA-koder, og deretter kontoer som har minst én føring med bruk av MVA-koden i valgt periode. Hver MVA-kode har en oppsummering, hvor du ser summen for både grunnlag og MVA og mva.</p>
<p>Dersom en konto har brukt ulike MVA-koder, vil kontoen bli listet opp flere ganger under riktig MVA-kode.</p>
<p>Alle de blå tekstene kan klikkes på for å redigere eller få utfyllende informasjon.</p>
<p>Det er mulig å laste ned rapporten ved å klikke på nedlastingsikonet øverst til høyre.</p>
  `,
  vatSpecificationTransactions: `
<p>Dette er en rapport for MVA-spesifikasjon på bilagsnivå/transaksjonsnivå. Rapporten viser alle bilag i en angitt periode, samt hvilke kontoer og MVA-koder som er brukt. Du kan også se grunnlaget i prosent, samt hva som utgjør grunnlag i kroner og MVA.</p>
<p>Øverst i rapporten er det mulig å benytte seg av ulike filtre. Filtrene brukes i fellesskap. For å se alle filtrene, må du klikke på den blå knappen "flere filtre" øverst til høyre. Systemet genererer en unik URL når du bruker filter, slik at det er enkelt å dele lenker.</p>
<p>Nederst i rapporten ser du summen totalt for grunnlag og summen totalt for MVA for alle bilag som vises i oversikten.</p>
<p>Alle de blå områdene kan klikkes på for å redigere eller få utfyllende informasjon.</p>
<p>Det er mulig å laste ned rapporten ved å klikke på nedlastingsikonet øverst til høyre. Du laster da ned rapporten med de filtrene som er brukt.</p>
  `,
  incomeBalance: `
<p>På denne siden finner du balanseregnskap og resultatregnskap. Begge rapportene er slått sammen til én rapport, og du kan se begge samlet eller bruke ulike filtre for å vise kun balanse, resultat eller kombinere tallene etter dine ønsker.</p>
<p>Dersom du trykker på "Balanserapport" elle "Resultatrapport" direkte i menyen, vil vi automatisk foreslå filtre for å vise kun balanse eller resultat. Du kan endre filtrene etter behov for å se mer eller mindre informasjon.</p>
<p>Rapporten har mange filtre som kan brukes i kombinasjon. Tryk på den blå knappen "flere filtre" for å vise alle filtrene. Når du bruker ett eller flere filtre, vil vi også lage en unik URL/link, slik at det er enkelt å sende lenken videre.</p>
<p>Rapporten iser faktiske bokførte tall, og ved ny bokføring vil rapporten oppdatere seg. Dersom rapporten har blå tekst, betyr det at du kan trykke på verdien for å få utfyllende informasjon.</p>
<p>Det er mulig å laste ned rapporten ved å rykke på nedlastingsikonet øverst til høyre. Du laster alltid ned rapporten med de brukte filtrene, slik at det du ser på skjermen er det samme innhodlet som kommer i nedlastet fil.</p>
  `,
  invoiceSettings: `
<p>På denne skjermen finner du innstillinger for utgående fakturaer. Hver seksjon har et infotegn som gir en detaljert forklaring på hva seksjonen gjør.</p>
`,
  createEditEmployee: `
<p>Inne på denne siden kan du legge til eller redigere en ansatt.</p>
<p>Siden har flere faner, og det er ulike kategorier inne på hver fane. Den første fanen inkluderer alle krav som kreves i a-meldingen, slik at det er effektivt å følge alle kategoriene nedover når man oppretter en ny ansatt.</p>
<p>En ansatt kan ha et, eller mange arbeidsforhold.</p>
<p>Alt inne på en fane lagres ved å bruke lagre knappen nederst på skjermen.</p>
`,
};
